import React, { createRef, useEffect, useState } from 'react';
import { urlStringForPathKey } from '@paddl/utils-js';
import { Icons, useViewportWidth } from '@paddl/storybook';
import { shape, string } from 'prop-types';
import {
  ActivityPaginationWrapper,
  LinkElementWrapper,
  LinkContentWrapper,
  LinkTextWrapper,
  IconWrapper,
  LinkText
} from './ActivityPagination.styles';

const { arrowBack: ArrowBack, arrowForward: ArrowForward } = Icons;

export const ActivityPagination = ({ next, previous }) => {
  const elementRef = createRef();

  const [wrapperWidth, setWrapperWidth] = useState(0);

  const width = useViewportWidth();

  useEffect(() => {
    if (elementRef?.current) {
      setWrapperWidth(elementRef.current.getBoundingClientRect().width - 35);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <ActivityPaginationWrapper>
      <LinkElementWrapper ref={elementRef}>
        {previous?.activity && (
          <LinkContentWrapper
            href={urlStringForPathKey('publicActivitiesById', {
              id: previous.activity.id
            })}
          >
            <IconWrapper>
              <ArrowBack />
            </IconWrapper>
            <LinkTextWrapper>
              <LinkText bold width={wrapperWidth}>Previous</LinkText>
              <LinkText width={wrapperWidth}>
                {previous.linkText}
              </LinkText>
            </LinkTextWrapper>
          </LinkContentWrapper>
        )}
      </LinkElementWrapper>
      <LinkElementWrapper>
        {next?.activity && (
          <LinkContentWrapper
            href={urlStringForPathKey('publicActivitiesById', {
              id: next.activity.id
            })}
            align="right"
          >
            <LinkTextWrapper>
              <LinkText bold width={wrapperWidth}>Next</LinkText>
              <LinkText width={wrapperWidth}>{next.linkText}</LinkText>
            </LinkTextWrapper>
            <IconWrapper>
              <ArrowForward />
            </IconWrapper>
          </LinkContentWrapper>
        )}
      </LinkElementWrapper>
    </ActivityPaginationWrapper>
  );
};

ActivityPagination.propTypes = {
  next: shape({
    activity: shape({}),
    linkText: string
  }),
  previous: shape({
    activity: shape({}),
    linkText: string
  })
};

ActivityPagination.defaultProps = {
  next: null,
  previous: null
};

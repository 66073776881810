import { getContentfulData } from '@paddl/utils-js';

export const getContentfulDataByActivity = async (activity) =>
  (activity.variantId === 4
    ? getContentfulData({
      content_type: 'paddlGames',
      'fields.eventId': activity.details.eventId
    })
    : Promise.resolve({}));

export const getContentfulDataByEventId = async (eventId) =>
  getContentfulData({
    content_type: 'paddlGames',
    'fields.eventId': eventId
  });

import { getActivityName } from '@paddl/utils-js';
import { getContentfulDataByActivity } from '../getContentfulData';

const getLinkText = async (activity) => {
  if (activity.variantId === 4) {
    const data = await getContentfulDataByActivity(activity);

    return data.items[0].fields.name;
  }

  return getActivityName(activity.details);
};

export const getNextAndPreviousActivity =  async (id, timeline) => {
  const index = timeline.findIndex(({ id: activityId }) => activityId === id);
  const previous = timeline?.[index + 1];
  const next = timeline?.[index - 1];

  return {
    previous: {
      linkText: previous ? await getLinkText(previous) : '',
      activity: previous
    },
    next: {
      linkText: next ? await getLinkText(next) : '',
      activity: next
    }
  };
};

import React from 'react';
import { shape, number } from 'prop-types';
import {
  BachelorDegreeRead,
  CasualWorkRead,
  CertificateRead,
  ContractRead,
  CourseRead,
  DiplomaRead,
  FullTimeRead,
  HackathonRead,
  HighSchoolRead,
  InternshipRead,
  MasterDegreeRead,
  MeetupRead,
  MembershipRead,
  PartTimeRead,
  PhdRead,
  PrizeRead,
  SideHustleRead,
  VolunteerRead,
  WorkshopRead
} from '@paddl/storybook';

import { PaddlGamesRead } from '../PaddlGamesRead';

export const ActivityDetails = ({
  activityDetails,
  variantId,
  contentfulData
}) => {
  const details = {
    details: activityDetails
  };

  switch (variantId) {
    case 1:
      return <BachelorDegreeRead data={details} />;
    case 2:
      return <MasterDegreeRead data={details} />;
    case 3:
      return <DiplomaRead data={details} />;
    case 4:
      return <PaddlGamesRead data={details} contentfulData={contentfulData} />;
    case 5:
      return <CourseRead data={details} />;
    case 6:
      return <CertificateRead data={details} />;
    case 7:
      return <HighSchoolRead data={details} />;
    case 8:
      return <InternshipRead data={details} />;
    case 9:
      return <CasualWorkRead data={details} />;
    case 10:
      return <FullTimeRead data={details} />;
    case 11:
      return <PartTimeRead data={details} />;
    case 12:
      return <ContractRead data={details} />;
    case 13:
      return <HackathonRead data={details} />;
    case 16:
      return <PhdRead data={details} />;
    case 17:
      return <SideHustleRead data={details} />;
    case 18:
      return <VolunteerRead data={details} />;
    case 19:
      return <PrizeRead data={details} />;
    case 20:
      return <MembershipRead data={details} />;
    case 21:
      return <MeetupRead data={details} />;
    case 22:
      return <WorkshopRead data={details} />;
    default:
      return null;
  }
};

ActivityDetails.propTypes = {
  variantId: number.isRequired,
  activityDetails: shape({}).isRequired,
  contentfulData: shape({})
};

ActivityDetails.defaultProps = {
  contentfulData: {}
};

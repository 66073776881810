import React from 'react';
import { hardRedirect } from '@paddl/utils-js';
import { Button } from '@paddl/storybook';

export const GlobalNavigation = () =>
  <>
    <Button
      onClick={() => window.location.assign('https://blog.paddl.com')}
      variant="contained"
      color="white"
      size="small"
    >
      Blog
    </Button>
    <Button
      onClick={() => hardRedirect('login')}
      size="small"
    >
      Log In
    </Button>
    <Button
      onClick={() => hardRedirect('register')}
      variant="contained"
      size="small"
    >
      Sign Up
    </Button>
  </>;

import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, H1, Colors, Body } from '@paddl/storybook';
import { LoginCardWrapper } from './LoginCard.styles';

export const LoginCard = () =>
  <LoginCardWrapper>
    <Card>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src="/assets/woman-on-laptop.svg"
        alt=""
        width="216"
        height="160"
      />
      <CardContent>
        <H1 data-test-id="title" style={{color: Colors.plum_paddl}}>Jump back in...</H1>
        <Body style={{color: Colors.new_grey}}>Let’s pick up some new skills.</Body>
      </CardContent>
      <CardActions>
        <Button
          className="button"
          href="/api/auth/login"
          fullWidth
          size="extra-large"
        >
          Log in
        </Button>
      </CardActions>
    </Card>
  </LoginCardWrapper>;

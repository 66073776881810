import styled from '@emotion/styled';
import { Colors, Fonts, Backgrounds } from '@paddl/storybook';

const {
  plum_dark,
  new_grey,
  grey_lighter,
  charcoal,
  plum_electric,
  plum_electric_active
} = Colors;

const {
  blueLandScapeBased64
}  = Backgrounds;

export const SiteMapHeaderOuterWrapper = styled.div`
  display: flex;
  background: ${`url('data:image/svg+xml;base64,${blueLandScapeBased64}') repeat-x bottom`};
`;

export const SiteMapHeaderInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 96px 0;
`;

export const HeroHeadline = styled.h1`
  ${Fonts.H0};
  color: ${plum_dark};
  margin: 0;
`;

export const SubHeadline = styled.h2`
  ${Fonts.headline32Thin_legacy};
  color: ${charcoal};
  margin: 64px 0;
`;

export const SubText = styled.p`
  ${Fonts.labelText};
  color: ${new_grey};
`;

export const SectionLinksWrapper = styled.div`
  border-bottom: 1px solid ${grey_lighter};
  padding: 26px 0 36px 0;
`;

export const SectionLink = styled.a`
  ${Fonts.linkText};
  color: ${({ isActive }) => (isActive ? charcoal : new_grey)};
  text-decoration: none;
  margin-right: 42px;
  padding-bottom: 10px;
  border-bottom: 3px solid
    ${({ isActive }) => (isActive ? plum_electric_active : 'transparent')};
`;
// 3 isSmall
export const ContentLinkWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ isSmall }) => (isSmall ? '2' : '3')}, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 0px;
`;

export const Links = styled.a`
  ${({ bold }) => (bold ? Fonts.bodyBold : Fonts.body)};
  margin-right: ${({ bold }) => (bold ? '8px' : '0')};
  color: ${plum_electric};
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export const SubNavigationLinksHeadline = styled.h3`
  ${Fonts.labelText}
  color: ${new_grey};
  margin: 0;
`;

export const SubNavigationLinksWrapper = styled.div`
  margin-bottom: 32px;
`;

export const SubNavigationLinksInnerWrapper = styled.div`
  overflow-x: auto;
  padding: 6px 0;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: space-between;
`;

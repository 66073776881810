const VARIANT_IDS_TO_USE_TITLE = [4];

const getActivityTitle = ({
  variantId,
  activityProviderName,
  contentfulData
}) => {
  const contentfulTitle = contentfulData?.items?.[0]?.fields?.name || '';

  return VARIANT_IDS_TO_USE_TITLE.includes(variantId) && contentfulTitle
    ? contentfulTitle
    : activityProviderName;
};

export const getActivitiesPageTitle = ({
  contentfulData,
  variantId,
  activityProviderName,
  activityName,
  organisationName,
  fullName
}) => {
  const activityTitle = getActivityTitle({
    variantId,
    activityProviderName,
    contentfulData
  });

  return `${activityTitle ? `${activityTitle}, ` : ''}${
    activityName ? `${activityName}` : ''
  }${organisationName ? ` at ${organisationName}` : ''} - ${fullName}`;
};

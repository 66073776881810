import { activityCategories, getActivityName, getOrganisationName } from '@paddl/utils-js';

const isNumber = (n) => Number.isInteger(Number(n));

const NONE_ACTIVE_CATEGORIES = [5];

export const categories = () =>
  activityCategories()
    .filter(({ categoryId }) => !NONE_ACTIVE_CATEGORIES.includes(categoryId))
    .map(({ categoryId, title }) => ({
      categoryId,
      title
    }));

export const learningActivitiesRequestIsValid = (query) => {
  const id = query?.idPage[0];
  const page = query?.idPage[1];

  if (!id && !page && query.idPage.length !== 2) {
    return false;
  }
  if (!isNumber(id) || !isNumber(page)) {
    return false;
  }

  if (page <= 0) {
    return false;
  }

  if (
    !categories().find(
      ({ categoryId }) => categoryId.toString() === id.toString()
    )
  ) {
    return false;
  }

  return true;
};

export const getActivityLinkText = (details, activityId) =>
  [getActivityName(details), getOrganisationName(details)]
    .filter(Boolean)
    .join(' - ') || `Activity #${activityId}`;

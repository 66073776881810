import React from 'react';
import { useRouter } from 'next/router';
import { arrayOf, string, shape } from 'prop-types';
import { useWidth, InnerContentWrapper } from '@paddl/storybook';
import { urlStringForPathKey } from '@paddl/utils-js';
import {
  SiteMapHeaderOuterWrapper,
  SiteMapHeaderInnerWrapper,
  ContentLinkWrapper,
  HeroHeadline,
  SubHeadline,
  SubText,
  SectionLinksWrapper,
  SubNavigationLinksWrapper,
  SubNavigationLinksHeadline,
  SubNavigationLinksInnerWrapper,
  SectionLink,
  Links,
  PaginationWrapper
} from './SitemapLayout.styles';

export const SitemapLayout = ({
  headline,
  subNavigationLinks,
  paginationLinks,
  contentLinks
}) => {
  const router = useRouter();
  const width = useWidth();

  return (
    <>
      <SiteMapHeaderOuterWrapper>
        <InnerContentWrapper>
          <SiteMapHeaderInnerWrapper>
            <HeroHeadline>Sitemap</HeroHeadline>
            <SubText>Global link directory for paddl.com</SubText>
          </SiteMapHeaderInnerWrapper>
        </InnerContentWrapper>
      </SiteMapHeaderOuterWrapper>
      <InnerContentWrapper>
        <SectionLinksWrapper>
          <SectionLink
            target="_self"
            href={urlStringForPathKey('sitemap')}
            isActive={router.route === '/sitemaps'}
          >
            Common Pages
          </SectionLink>
          <SectionLink
            target="_self"
            href={urlStringForPathKey('sitemapPeopleDirectoryByLetter', { id: 'a' })}
            isActive={router.route === '/sitemaps/people-directory/[id]'}
          >
            People Directory
          </SectionLink>
          <SectionLink
            target="_self"
            href={urlStringForPathKey('sitemapLearningActivitiesById', { categoryId: '1', id: '1' })}
            isActive={router.route === '/sitemaps/learning-activities/[...idPage]'}
          >
            Learning Activities
          </SectionLink>
        </SectionLinksWrapper>
        <SubHeadline hasSubNav={!!subNavigationLinks.length}>{headline}</SubHeadline>
        {subNavigationLinks.length ? (
          <SubNavigationLinksWrapper>
            <SubNavigationLinksHeadline>Browse By:</SubNavigationLinksHeadline>
            <SubNavigationLinksInnerWrapper>
              {subNavigationLinks.map(({ href, linkText }) => (
                <Links key={href} target="_self" href={href} bold>
                  {linkText}
                </Links>
              ))}
            </SubNavigationLinksInnerWrapper>
          </SubNavigationLinksWrapper>
        ) : ''}
        {contentLinks && (
          <ContentLinkWrapper isSmall={width === 'xs' || width === 'sm'}>
            {contentLinks.map(({ href, linkText }) => (
              <Links key={href} target="_self" href={href}>
                {linkText}
              </Links>
            ))}
          </ContentLinkWrapper>
        )}
        {paginationLinks && (
          <PaginationWrapper>
            <div>
              {paginationLinks?.previous && (
                <Links data-test-id="previous-link" href={paginationLinks.previous} bold>
                  Previous
                </Links>
              )}
            </div>
            <div>
              {paginationLinks?.next && (
                <Links data-test-id="next-link" href={paginationLinks.next} bold>
                  Next
                </Links>
              )}
            </div>
          </PaginationWrapper>
        )}
      </InnerContentWrapper>
    </>
  );
};

SitemapLayout.propTypes = {
  headline: string.isRequired,
  subNavigationLinks: arrayOf(
    shape({
      linkText: string,
      href: string
    })
  ),
  contentLinks: arrayOf(
    shape({
      linkText: string,
      href: string
    })
  ).isRequired,
  paginationLinks: shape({
    next: string,
    previous: string
  })
};

SitemapLayout.defaultProps = {
  subNavigationLinks: [],
  paginationLinks: null
};

import React from 'react';
import { shape } from 'prop-types';
import { getCompetitionPlaceByName } from '@paddl/utils-js';
import {
  DetailsWrapper,
  LogoCardWithText,
  Icons,
  getRoles
} from '@paddl/storybook';

export const PaddlGamesRead = ({ data, contentfulData }) => {
  const Award = Icons.award;

  const {
    competitionPlace,
    roleCreative,
    roleDemonstrator,
    rolePresenter,
    roleTeamLeader
  } = data.details;

  const {
    name,
    location,
    hostCompany,
    challengeFocus
  } = contentfulData.items[0].fields;

  return (
    <DetailsWrapper>
      <h1 className="headline">{name}</h1>
      <span className="location">{location}</span>
      <LogoCardWithText
        avatarUrl={`https://${hostCompany.icon}`}
        title={hostCompany.title}
        subtitle="Host Company"
        isSubtitleShortened
      />
      <p>{challengeFocus}</p>
      {competitionPlace < 3 ? (
        <div className="place" data-test-id="competition-place">
          <span className="icon">
            <Award />
          </span>
          <span>{getCompetitionPlaceByName(competitionPlace)}</span>
        </div>
      ) : null}
      <div className="tags">
        {getRoles([
          roleTeamLeader,
          rolePresenter,
          roleDemonstrator,
          roleCreative
        ])}
      </div>
    </DetailsWrapper>
  );
};

PaddlGamesRead.propTypes = {
  data: shape({}).isRequired,
  contentfulData: shape({}).isRequired
};

import React, { useState, createRef, useEffect } from 'react';
import { number } from 'prop-types';
import Grid from '@mui/material/Grid';
import { activityCategories } from '@paddl/utils-js';
import {
  ChartsWrapper,
  useViewportWidth,
  DoughnutChart,
  BarChart,
  ActivityColorPaletteLight,
  SectionHeader,
  Section
} from '@paddl/storybook';
import { getProfileSkills, getProfileXpByCategory } from '../../utils';

const  SMALL_WRAPPER_BREAK_POINT = 280;

const getChartProps = (skills) => {
  const labels = [];
  const data = [];

  skills.forEach(({ name, count }) => {
    labels.push(name);
    data.push(count);
  });

  return { labels, data };
};

export const ProfileCharts = ({
  profileId
}) => {
  const [isWrapperSmall, setIsWrapperSmall] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [xpByCategory, setXpByCategory] = useState();
  const [skills, setSkills] = useState([]);
  const width = useViewportWidth();

  const isViewPortSmall = width === 'xs' || width === 'sm'

  useEffect(() => {

    const loadData = async () => {
      await Promise.all([
        getProfileXpByCategory(profileId).then((response) => setXpByCategory(response.xpByCategory)),
        getProfileSkills(profileId).then((response) => setSkills(response.skills))
      ])
      setIsLoading(false)
    }
    loadData()

  }, [profileId])

  const elementRef = createRef();

  const { labels, data } = getChartProps(
    skills.filter((_, index) => index < 5) || []
  );

  useEffect(() => {
    if (elementRef?.current) {
      setIsWrapperSmall(
        elementRef.current.getBoundingClientRect().width <
          SMALL_WRAPPER_BREAK_POINT
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  if (isLoading) {
    return null;
  }

  return (
    <Section>
      <SectionHeader>Experience</SectionHeader>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <ChartsWrapper
            headLine="Top Skills"
            tagLine="By number of Activities"
            tooltipText="Shows the top 5 skills by the number of Activities they have been tagged in"
            shouldShowTooltip={!isViewPortSmall}
            chartBackgroundColor="purple"
            chartPadding="small"
          >
            <BarChart
              data-test-id="bar-chart"
              labels={labels}
              data={data}
            />
          </ChartsWrapper>
        </Grid>
        <Grid item xs={12} md={4} ref={elementRef}>
          <ChartsWrapper
            headLine="XP by Category"
            tagLine="% of Total Experience"
            tooltipText="Shows the % of Experience Points in each of the 6 Experience Categories."
            shouldShowTooltip={!isViewPortSmall}
            chartBackgroundColor="yellow"
            chartPadding={isWrapperSmall ? 'medium' : 'large'}
          >
            <DoughnutChart
              data-test-id="doughnut-chart"
              label="Xp by category"
              displayLegend={false}
              labels={xpByCategory.map(
                ({ categoryId }) =>
                  activityCategories().find(
                    (activityCategory) =>
                      activityCategory.categoryId === categoryId
                  ).title
              )}
              backgroundColor={xpByCategory.map(
                ({ categoryId }) =>
                  ActivityColorPaletteLight[
                    activityCategories().find(
                      (activityCategory) =>
                        activityCategory.categoryId === categoryId
                    ).id
                  ]
              )}
              data={xpByCategory.map(
                ({ collectedXpNumber }) => collectedXpNumber
              )}
            />
          </ChartsWrapper>
        </Grid>
      </Grid>
    </Section>
  );
};

ProfileCharts.propTypes = {
  profileId: number.isRequired
};

import React from 'react';
import {
  PublicNav,
  PublicFooterMetaSocial,
  PublicFooterCopyright
} from '@paddl/storybook';
import { string, node } from 'prop-types';
import { PageMeta } from '../PageMeta';
import {GlobalNavigation} from '../GlobalNavigation'
import { PageWrapper } from '../../styles/components/layout.styles';

export const Layout = ({
  pageTitle,
  activityTitle,
  pageDescription,
  pagePathname,
  pageTags,
  pagePublishedTime,
  pageMetaImageUrl,
  pageType,
  navigationTitle,
  children
}) =>
  <PageWrapper>
    <PageMeta
      title={pageTitle}
      description={pageDescription}
      activityTitle={activityTitle}
      pagePathname={pagePathname || undefined}
      pageTags={pageTags || undefined}
      pagePublishedTime={pagePublishedTime || undefined}
      pageType={pageType || undefined}
      pageMetaImageUrl={pageMetaImageUrl || undefined}
    />
    <PublicNav
      loginUrl="/api/auth/login"
      registerUrl="/api/auth/login?initialPage=register"
      pageTitle={navigationTitle || ''}
    >
      <GlobalNavigation />
    </PublicNav>
    <main>
      {children}
    </main>
    <footer>
      <PublicFooterMetaSocial />
      <PublicFooterCopyright />
    </footer>
  </PageWrapper>;

Layout.propTypes = {
  pageTitle: string.isRequired,
  pageDescription: string.isRequired,
  children: node.isRequired,
  activityTitle: string,
  pagePathname: string,
  pageTags: string,
  pagePublishedTime: string,
  pageType: string,
  pageMetaImageUrl: string,
  navigationTitle: string
};

Layout.defaultProps = {
  activityTitle: '',
  pagePathname: '',
  pageTags: '',
  pagePublishedTime: '',
  pageType: '',
  pageMetaImageUrl: '',
  navigationTitle: ''
};

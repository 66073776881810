import styled from '@emotion/styled';
import {
  Elevations,
  borderRadius,
  viewportWidthSmall
} from '@paddl/storybook';

export const LoginCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;

  @media (max-width: ${viewportWidthSmall}) {
    margin-top: 64px;
    margin-bottom: 32px;
  }

  img {
    margin: 32px 46px 22px;
    width: auto;
  }

  .MuiPaper-root {
    padding: 32px 16px;
  }

  .MuiPaper-elevation1 {
    box-shadow: ${Elevations.elevation};
  }

  .MuiPaper-rounded {
    border-radius: ${borderRadius};
  }

  .MuiCardActions-root {
    padding: 0;

    > div {
      width: 100%;
    }
  }

  .MuiCardActionArea-focusHighlight {
    display: none;
  }
`;


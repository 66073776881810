import styled from '@emotion/styled';
import { Fonts, Colors } from '@paddl/storybook';

const {
  grey_lighter,
  new_grey,
  new_grey_light,
  plum_electric_active
} = Colors;

export const ActivityPaginationWrapper = styled.div`
  display: flex;
  padding: 64px 0;
  justify-content: space-between;
  gap: 12px;
  border-top: 1px solid ${grey_lighter};
  margin-top: 64px;
`;

export const LinkContentWrapper = styled.a`
  display: flex;
  text-align: ${({ align = 'left' }) => (align)};
  align-items: center;
  text-decoration: none;
  gap: 10px;

  svg {
    fill: ${new_grey};
    height: 40px;
  }

  &:hover p {
    color: ${plum_electric_active};
  }

  &:hover svg {
    fill: ${plum_electric_active};
  }
`;

export const LinkText = styled.p`
  ${({ bold = false }) => (bold ? Fonts.body : Fonts.bodyBold)};
  color: ${({ bold = false }) => (bold ? new_grey : new_grey_light)};
  margin: 0;
  width: ${({ width }) => (width)}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LinkTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LinkElementWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 50%;
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 20px;
  overflow: auto;

  svg {
    margin-left: -22px;
    margin-top: 5px;
  }
`;

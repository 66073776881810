import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { string } from 'prop-types';

export const PageMeta = ({
  title,
  description,
  pagePathname,
  pageTags,
  pagePublishedTime,
  pageType,
  pageMetaImageUrl
}) =>  {
  const router = useRouter();

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
      <link rel="canonical" href={`https://www.paddl.com${pagePathname || router.pathname}`} />
      <title>{`${title} | Paddl`}</title>
      <meta name="description" content={description} />

      {/* Open Graph */}
      <meta property="og:site_name" content="Paddl" key="ogSiteName" />
      <meta property="og:url" content={`https://www.paddl.com${pagePathname || router.pathname}`} key="ogUrl" />
      <meta property="og:type" content={pageType} key="ogType" />
      <meta property="og:title" content={`${title} | Paddl`} key="ogTitle" />
      <meta property="og:description" content={description} key="ogDesc" />
      <meta property="og:image" content={pageMetaImageUrl} key="ogimage" />
      <meta property="og:image:width" content="600" key="ogImageWidth" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" key="twCard" />
      <meta name="twitter:site" content="@paddl" key="twSite" />
      <meta name="twitter:title" content={`${title} | Paddl`} key="twTitle" />
      <meta name="twitter:description" content={description} key="twDesc" />
      <meta name="twitter:image" content={pageMetaImageUrl} key="twImage" />
      <meta name="twitter:image:alt" content={`Paddl | ${title}`} key="twImageAlt" />

      {/* General */}
      <meta property="article:publisher" content="https://www.facebook.com/paddl/" />
      <meta property="article:author" content="https://www.facebook.com/paddl/" />
      <meta property="article:tag" content={pageTags || 'Learning'} />
      <meta property="article:published_time" content={pagePublishedTime} />
    </Head>
  );
};

PageMeta.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  pagePathname: string,
  pageTags: string,
  pagePublishedTime: string,
  pageType: string,
  pageMetaImageUrl: string
};

PageMeta.defaultProps = {
  pagePathname: '',
  pageTags: 'Paddl',
  pageType: 'article',
  pageMetaImageUrl: '',
  pagePublishedTime: ''
};

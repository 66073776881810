import React from 'react';
import { node } from 'prop-types';
import { PageTabs, getProfileHeroMinHeight, useWidth } from '@paddl/storybook';

export const TabContent = ({ activities, summary }) => {
  const width = useWidth();
  const mainNavBarHeight = 62;

  return (
  <PageTabs
    usedViewHeight={getProfileHeroMinHeight(width) + mainNavBarHeight}
    tabLabels={[
      {
        label: <span>Summary</span>,
        key: 'summary'
      },
      {
        label: <span>Activities</span>,
        key: 'activities'
      }
    ]}
    tabPanels={[
      {
        key: 'summary',
        item: summary
      },
      {
        key: 'activities',
        item: activities,
        colorVariant: 'dark'
      }
    ]}
  />)};

TabContent.propTypes = {
  activities: node,
  summary: node
};

TabContent.defaultProps = {
  activities: null,
  summary: null
};

import styled from '@emotion/styled';
import {  viewportWidthMedium } from '@paddl/storybook';

export const PageWrapper = styled.div`
  padding-top: 62px;
  overflow: hidden;

  @media (max-width: ${viewportWidthMedium}) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  footer {
    margin-top: auto;
  }
`;
